/* eslint no-undef: 0 */
import { createGetKcContext } from 'keycloakify/login';
import { profile } from './constants/userProfileInputs';
import { socialProviers } from './constants/socialProviders';

export type KcContextExtension =
  // WARNING: It's important to keep in sync the extraThemeProperties declared in the package.json and this type definition.
  | {
      pageId: 'error.ftl';
      client: { attributes: { 'post.logout.redirect.uris': string } };
    }
  | {
      pageId: 'info.ftl';
      client: { attributes: { 'post.logout.redirect.uris': string } };
    }
  | { pageId: 'login.ftl'; extraThemeProperties: { foo: string } }
  | { pageId: 'my-extra-page-1.ftl' }
  | { pageId: 'my-extra-page-2.ftl'; someCustomValue: string }
  // NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
  // but let's say we use it anyway and have this plugin enabled: https://github.com/micedre/keycloak-mail-whitelisting
  // keycloak-mail-whitelisting define the non standard ftl global authorizedMailDomains, we declare it here.
  | { pageId: 'register.ftl'; authorizedMailDomains: Array<string> }
  | { pageId: 'view-email.ftl' };

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: 'error.ftl',
      locale: {
        currentLanguageTag: 'en',
      },
      //Uncomment the following line for hiding the Alert message
      // message: undefined,
      //Uncomment the following line for showing an Error message
      message: { type: 'error', summary: 'This is an error' },
      realm: {
        displayName: 'Acrolinx',
        displayNameHtml: 'Acrolinx',
        internationalizationEnabled: false,
        name: 'Acrolinx',
        registrationEmailAsUsername: true,
      },
      client: {
        attributes: {
          'post.logout.redirect.uris': 'something##someurl',
        },
      },
    },
    {
      pageId: 'login.ftl',
      locale: {
        currentLanguageTag: 'en',
      },
      //Uncomment the following line for hiding the Alert message
      // message: undefined,
      //Uncomment the following line for showing an Error message
      message: { type: 'error', summary: 'Incorrect username or password' },
      realm: {
        displayName: 'Acrolinx',
        displayNameHtml: 'Acrolinx',
        internationalizationEnabled: false,
        loginWithEmailAllowed: true,
        name: 'microsoft-ce-csi',
        password: true,
        registrationAllowed: false,
        registrationEmailAsUsername: true,
        rememberMe: false,
        resetPasswordAllowed: true,
      },
      social: {
        providers: socialProviers,
      },
    },
    {
      pageId: 'login-username.ftl',
      locale: {
        currentLanguageTag: 'en',
      },
      //Uncomment the following line for hiding the Alert message
      message: undefined,
      //Uncomment the following line for showing an Error message
      // message: {type: "error", summary: "This is an error"},
      realm: {
        displayName: 'Acrolinx',
        displayNameHtml: 'Acrolinx',
        internationalizationEnabled: false,
        loginWithEmailAllowed: true,
        name: 'microsoft-ce-csi-qa',
        password: true,
        registrationAllowed: false,
        registrationEmailAsUsername: true,
        rememberMe: false,
        resetPasswordAllowed: false,
      },
      social: {
        providers: socialProviers,
      },
    },
    {
      pageId: 'view-email.ftl',
      locale: {
        currentLanguageTag: 'en',
      },
      //Uncomment the following line for hiding the Alert message
      message: undefined,
      //Uncomment the following line for showing an Error message
      // message: {type: "error", summary: "This is an error"},
      realm: {
        displayName: 'Acrolinx',
        displayNameHtml: 'Acrolinx',
        internationalizationEnabled: false,
        name: 'Acrolinx',
        registrationEmailAsUsername: true,
      },
      auth: {
        attemptedUsername: 'someemail@email.yeah',
      },
    },
    {
      pageId: 'info.ftl',
      locale: {
        currentLanguageTag: 'en',
      },
      // @ts-expect-error error, success and warning props are not typed properly
      message: { summary: 'You are already logged in.', type: 'success', error: false, success: true, warning: false },
      realm: {
        displayName: 'Acrolinx',
        displayNameHtml: 'Acrolinx',
        internationalizationEnabled: false,
        name: 'Acrolinx',
        registrationEmailAsUsername: true,
        registrationAllowed: false,
        resetPasswordAllowed: false,
        password: true,
        loginWithEmailAllowed: true,
        rememberMe: false,
      },
      // requiredActions: ['CONFIGURE_TOTP', 'terms_and_conditions'],
    },
    // {
    //   pageId: "my-extra-page-2.ftl",
    //   someCustomValue: "foo bar baz",
    // },
    // {
    //   //NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
    //   pageId: "register-user-profile.ftl",
    //   locale: {
    //     currentLanguageTag: "fr",
    //   },
    //   profile: {
    //     attributes: [
    //       {
    //         validators: {
    //           pattern: {
    //             pattern: "^[a-zA-Z0-9]+$",
    //             "ignore.empty.value": true,
    //             // eslint-disable-next-line no-template-curly-in-string
    //             "error-message": "${alphanumericalCharsOnly}",
    //           },
    //         },
    //         //NOTE: To override the default mock value
    //         value: undefined,
    //         name: "username",
    //       },
    //       {
    //         validators: {
    //           options: {
    //             options: [
    //               "male",
    //               "female",
    //               "non-binary",
    //               "transgender",
    //               "intersex",
    //               "non_communicated",
    //             ],
    //           },
    //         },
    //         // eslint-disable-next-line no-template-curly-in-string
    //         displayName: "${gender}",
    //         annotations: {},
    //         required: true,
    //         groupAnnotations: {},
    //         readOnly: false,
    //         name: "gender",
    //       },
    //     ],
    //   },
    // },
    // {
    //   pageId: "register.ftl",
    //   authorizedMailDomains: [
    //     "example.com",
    //     "another-example.com",
    //     "*.yet-another-example.com",
    //     "*.example.com",
    //     "hello-world.com",
    //   ],
    //   // Simulate we got an error with the email field
    //   messagesPerField: {
    //     printIfExists: <T>(fieldName: string, className: T) => {
    //       console.log({fieldName});
    //       return fieldName === "email" ? className : undefined;
    //     },
    //     existsError: (fieldName: string) => fieldName === "email",
    //     get: (fieldName: string) => `Fake error for ${fieldName}`,
    //     exists: (fieldName: string) => fieldName === "email",
    //   },
    // },
    {
      pageId: 'update-user-profile.ftl',
      message: {
        summary:
          'Your administrator has requested that you update your user information. Enter any missing information, and make sure that all existing information is correct.',
        type: 'warning',
      },
      realm: {
        internationalizationEnabled: false,
      },
      profile,
    },
    {
      pageId: 'login-update-profile.ftl',
      message: {
        summary:
          'Your administrator has requested that you update your user information. Enter any missing information, and make sure that all existing information is correct.',
        type: 'warning',
      },
      realm: {
        internationalizationEnabled: false,
      },
    },
    {
      pageId: 'login-update-password.ftl',
      message: {
        summary: 'You need to change your password to activate your account.',
        type: 'warning',
      },
      realm: {
        internationalizationEnabled: false,
      },
    },
    {
      pageId: 'idp-review-user-profile.ftl',
      message: {
        summary:
          'Your administrator has requested that you update your user information. Enter any missing information, and make sure that all existing information is correct.',
        type: 'warning',
      },
      realm: {
        internationalizationEnabled: false,
      },
      profile,
    },
    {
      pageId: 'login-reset-password.ftl',
      realm: {
        internationalizationEnabled: false,
      },
    },
  ],
});

export const { kcContext } = getKcContext({
  mockPageId: 'login-username.ftl',
});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>;
